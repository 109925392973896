import { Component, ViewChild, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { SwPush } from '@angular/service-worker';

import { MenuService } from '../_services/menu.service';
import { AuthenticationService } from '../_services/authentication.service';
import { PermissionService } from '../_services/permission.service';
import { SettingService } from '../_services/setting.service';
import { LanguageService } from '../_services/language.service';
import { AlertService } from '../_services/alert.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../_services/data.service';
import { ListService } from '../_services/list.service';
import { DateTimeService } from '../_services/datetime.service';
import { GeneralService } from '../_services/general.service';
import { encode } from 'querystring';

@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html'
})
export class TopMenuComponent implements OnInit, OnDestroy {

  @ViewChild('sweFocusElement') focusElement: ElementRef;
  @ViewChild('sweFocusElement2') focusElement2: ElementRef;

  private _subscriptions: Subscription[] = [];
  private _profilemenu: boolean = false;
  private _impersonatemenu: boolean = false;
  private _pincode: string = '';
  private _pinfocus: boolean = false;
  private _historymenu: boolean = false;
  private _historynew: boolean = false;
  //PowerSearch
  private _current: number = -1;
  private _more: boolean = false;
  private _all: boolean = false;
  private _nohits: boolean = false;
  private _total: number = 0;
  private _searchresult: any[] = [];
  private _searchstring: any;
  private _multiple: number = 1;
  private _isSearching: boolean;
  //Timeouts
  private _lostfocustimeout: any;
  private _searchtimeout: any;
  //Push
  private _pushaccepted: boolean = false;
  private _activepushsubscription: any = null;
  private _pushloading = false;

  constructor(
    public menuService: MenuService,
    public authenticationService: AuthenticationService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    public languageService: LanguageService,
    public alertService: AlertService,
    private dataService: DataService,
    private listService: ListService,
    private dateTimeService: DateTimeService,
    private generalService: GeneralService,
    private router: Router,
    private swPush: SwPush
  ) {

    this._subscriptions.push(menuService.onMenuChange$
      .subscribe((refresh) => {

        if (!refresh) {
          setTimeout(() => {
            if (this.focusElement) {
              let element = this.focusElement.nativeElement;
              element.focus();
            }
          }, 0); //Create a macrotask that will run in the next VM turn
        }

      }));

    this._subscriptions.push(menuService.onSearchChange$
      .subscribe((refresh) => {

        if (!refresh) {
          setTimeout(() => {
            if (this.focusElement2) {
              let element = this.focusElement2.nativeElement;
              element.focus();
            }
          }, 0); //Create a macrotask that will run in the next VM turn
        }

      }));

    this._subscriptions.push(alertService.onNewHistory$
      .subscribe((e) => {
        this._historynew = true;
      }));

  }

  ngOnInit() {

    this.checkforactivepushregistration();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }


  //Properties
  public get profilemenu() {
    return this._profilemenu;
  }
  public set profilemenu(val) {
    this._profilemenu = val;
  }
  public get impersonatemenu() {
    return this._impersonatemenu;
  }
  public set impersonatemenu(val) {
    this._impersonatemenu = val;
  }
  public get pincode() {
    return this._pincode;
  }
  public set pincode(val) {
    this._pincode = val;
  }
  public get pinfocus() {
    return this._pinfocus;
  }
  public set pinfocus(val) {
    this._pinfocus = val;
  }
  public get historymenu() {
    return this._historymenu;
  }
  public set historymenu(val) {
    this._historymenu = val;
  }
  public get historynew() {
    return this._historynew;
  }
  public get searchstring() {
    return this._searchstring;
  }
  public set searchstring(val) {
    this._searchstring = val;
  }
  public get current() {
    return this._current;
  }
  public get more() {
    return this._more;
  }
  public get all() {
    return this._all;
  }
  public get nohits() {
    return this._nohits;
  }
  public get total() {
    return this._total;
  }
  public get isSearching() {
    return this._isSearching;
  }
  public get searchresult() {
    return this._searchresult;
  }
  public get pushaccepted() {
    return this._pushaccepted;
  }
  public get pushloading() {
    return this._pushloading;
  }


  //Methods
  public toggle() {
    this._profilemenu = false;
    this._impersonatemenu = !this._impersonatemenu;
    this._pinfocus = this._impersonatemenu;
  }
  public goto(url) {
    this.router.navigateByUrl(url);
    this._profilemenu = false;
  }
  public keyup(e) {
    if (e.keyCode == 13) {
      this.impersonate();
      this._pinfocus = false;
    }
  }
  public impersonate() {

    this.authenticationService.impersonate(this.permissionService.isimpersonate(), this._pincode)
      .subscribe((res) => {

        setTimeout(() => {
          this.alertService.Add({ type: 'success', message: this.languageService.getItem(317) + ': ' + this.permissionService.user.Firstname + ' ' + this.permissionService.user.Lastname });

          let currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        }, 1000);
      });

    this._pincode = '';
    this._impersonatemenu = false;
    this._historymenu = false;
  }
  public toggleHistory() {
    this._profilemenu = false;
    this._impersonatemenu = false;
    this._pinfocus = this._impersonatemenu;
    this._historymenu = !this._historymenu;
    this._historynew = false;
  }
  public manageHistory(alert, e) {
    e.stopPropagation();
    this.alertService.PopHistory(alert); 
    if (this.alertService.History.length == 0) {
      this._historymenu = false;
    }
  }
  public manageHistoryAll(e) {
    e.stopPropagation();

    this.alertService.ClearHistory();
    this._historymenu = false;
  }


  public getIndex(x, y) {

    let idx = 0;
    for (let i = 0; i < x; i++) {
      idx += this._searchresult[i].rows.length;
    }

    idx += y;

    return idx;
  }
  public getItem(currentRow) {

    let item = null;
    for (let i = 0; i < this._searchresult.length; i++) {
      for (let j = 0; j < this._searchresult[i].rows.length; j++) {
        let idx = this.getIndex(i, j);
        if (idx == currentRow) {
          item = this._searchresult[i].rows[j];
          break;
        }
      }
      if (item != null) {
        break;
      }
    }

    return item;
  }

  public keyupSearch(e) {
    let currentRow = this._current;

    if (e.keyCode == 40) {
      //Arrow key down
      if (currentRow < this._total - 1) {
        currentRow++;
      }
      else if (this._all && currentRow == this._total) {
        currentRow++;
      }
      else if (this._more && currentRow == this._total - 1) {
        currentRow++;
      }
      else {
        currentRow = 0;
      }
    }
    else if (e.keyCode == 38) {
      //Arrow key up
      if (currentRow > 0) {
        currentRow--;
      }
      else if (this._all) {
        currentRow = this._total + 1;
      }
      else if (this._more) {
        currentRow = this._total
      }
      else {
        currentRow = this._total - 1;
      }
    }
    else if (e.keyCode == 13) {
      //Enter
      if (this._all && currentRow == this._total + 1) {
        this.search(2);
      }
      else if (this._more && currentRow == this._total) {
        this.search(1);
      }
      else if (!this._nohits) {
        if (this._current == -1) {
          this.search(0);
        }
        else {
          this.get(this.getItem(currentRow));
        }
      }
    }

    this._current = currentRow;
  }
  public keydownSearch(e) {
    let currentRow = this._current;

    if (e.keyCode == 9) {
      //Tab
      if (this._searchstring.length > 0) {
        let i = Math.floor(currentRow / (this._searchresult.length + 1));
        let j = currentRow % (this._searchresult.length + 1);
        this.get(this._searchresult[i].rows[j]);
      }
      else {
        return true;
      }
    }
  }
  public lostfocusSearch(e) {
    //If you already have a timout, clear it
    if (this._lostfocustimeout) { clearTimeout(this._lostfocustimeout); }

    //Start new timeout
    this._lostfocustimeout = setTimeout(() => {

      this.resetSearch();

    }, 500);
  }

  public search(getmore: number) {

    //If you already have a timout, clear it
    if (this._lostfocustimeout) { clearTimeout(this._lostfocustimeout); }

    //If you already have a timout, clear it
    if (this._searchtimeout) { clearTimeout(this._searchtimeout); }


    //Start new timeout
    this._searchtimeout = setTimeout(() => {

      if (this._searchstring.length == 0) {
        this.resetSearch();
        return;
      }

      let top = 3;
      if (getmore == 2) {
        //All
        top = 0;
        this._total = 0;
        this._searchresult = [];
      }
      else if (getmore == 1) {
        //More
        this._multiple++;
      }
      else {
        this._multiple = 1;
        this._total = 0;
        this._searchresult = [];
      }

      let first = this._total == 0;

      this._isSearching = true;
      this._current = 0;
      this._more = false;
      this._all = false;

      if (this.permissionService.permissions.Users > 0) {
        this.searchUsers(top, this._searchstring, first);
      }
      if (this.permissionService.permissions.Bookings > 0) {
        this.searchShifts(top, this._searchstring, first);
      }
      if (this.permissionService.permissions.Levels > 0) {
        this.searchLevels(top, this._searchstring, first);
      }
      this.listService.formatArray(this.generalService.propertycategories, ['Object'], 'Extra').forEach((category) => {
        if (this.permissionService.hasObject(category.Key) > 0) {
          this.searchObjects(top, this._searchstring, first, category);
        }
      });

    }
      , 500);
  }
  public get(item) {

    let url = item.path + item.id;

    this.router.navigate([url]);

    this.resetSearch();
  }



  /*Push Notification*/
  public async togglePushSupport(e) {

    this._pushloading = true;

    e.stopPropagation();

    try {
      if (this.swPush.isEnabled) {
        if (!this._pushaccepted) {

          //Registration
          const sub = await this.swPush.requestSubscription({
            serverPublicKey: 'BEUhCAQpT8rVIpUtqxgLpF2pZFxCIwfqO6VjTnDcO_uFrYhj9mmemmy2-9eW9CZoN2UDzKBPSM6pfvN62_EzheA'
          });

          //OnNotificationClick
          this.swPush.notificationClicks.subscribe((res) => {
            console.log(res);
          });

          //Check registration
          this.checkforactivepushregistration();

          //Send subscription to server.
          this.dataService.tokenRequest('/api/v1/PushNotification/subscribe', 'POST', sub, 'text', 'response')
            .subscribe(response => {

              if (response) {
                //Message to client
                this._pushloading = false;
                this.alertService.Add({ message: response.body, type: 'success' });
              }

            });

          console.log(JSON.stringify(sub));
        }
        else {
          
          if (this._activepushsubscription != null) {

            //Send unsubscribe to server
            this.dataService.tokenRequest('/api/v1/PushNotification/unsubscribe/', 'POST', this._activepushsubscription , 'text', 'response')
              .subscribe(response => {
                if (response) {

                  //Unsubscribe
                  this.swPush.unsubscribe();

                  //Check registration
                  this.checkforactivepushregistration();

                  //Message to client
                  this._pushloading = false;
                  this.alertService.Add({ message: response.body, type: 'success' });
                }
              });
          }
        }
      }
    } catch (err) {
      //Error to client
      this._pushloading = false;
      this.alertService.Add({ type: 'danger', message: 'Det gick inte att hantera registreringen av push-notiser. Kontrollera om du har blockerat aviseringar för detta system. ' + err});

      //Check registration
      this.checkforactivepushregistration();

      console.error('Could not subscribe due to:', err);
    }
  }
  private checkforactivepushregistration() {

    if (this.swPush.isEnabled) {

      this.swPush.subscription.subscribe((pushsubscription) => {
        this._pushaccepted = (pushsubscription != null);
        this._activepushsubscription = pushsubscription;
      });

    }

  }









  private searchUsers(top, searchstr, first) {

    let filter = {
      Search: searchstr,
      Top: top,
      Multiple: this._multiple,
      Active: true
    };

    this.dataService.tokenRequest('/api/v1/users/search', 'POST', filter)
      .subscribe(res => {

        if (res.Users.length > 0) {
          let groupresult: GroupResult = { type: 0, header: this.languageService.getItem(2), rows: [], more: false };
          let exist = this.listService.find(this._searchresult, 'type', 0);
          if (exist) {
            groupresult = exist;
          }

          res.Users.forEach((item) => {
            let groupitem: GroupItem = { id: item.Id, path: '/users/', description: item.Firstname + ' ' + item.Lastname + ' (' + item.Username + ')' };
            groupresult.rows.push(groupitem);
            this._total++;
          });

          groupresult.more = res.More;
          if (res.More) {
            this._more = true;
            this._all = !first;
          }

          if (!exist) {
            this._searchresult.push(groupresult);
          }
        }

      });
  }
  private searchShifts(top, searchstr, first) {

    let filter = {
      Start: this.settingService.start('booking'),
      PowerSearch: searchstr,
      Top: top,
      Multiple: this._multiple
    };

    this.dataService.tokenRequest('/api/v1/bookings/search', 'POST', filter)
      .subscribe(res => {

        if (res.Bookings.length > 0) {

          let groupresult: GroupResult = { type: 2, header: this.languageService.getItem(4), rows: [], more: false };
          let exist = this.listService.find(this._searchresult, 'type', 2);
          if (exist) {
            groupresult = exist;
          }

          res.Bookings.forEach((item) => {
            let start = new Date(item.Start);
            let end = new Date(item.End);

            let groupitem: GroupItem = { id: item.Id, path: '/bookings/', description: this.dateTimeService.header(start, end, false, true) + ' ' + item.Header + ' ' + item.Level };
            groupresult.rows.push(groupitem);
            this._total++;
          });

          groupresult.more = res.More;
          if (res.More) {
            this._more = true;
            this._all = !first;
          }

          if (!exist) {
            this._searchresult.push(groupresult);
          }
        }

      });

  }
  private searchLevels(top, searchstr, first) {

    let filter = {
      Name: searchstr,
      Top: top,
      Multiple: this._multiple
    };

    this.dataService.tokenRequest('/api/v1/levels/search', 'POST', filter)
      .subscribe(res => {

        if (res.Levels.length > 0) {

          let groupresult: GroupResult = { type: 1, header: this.languageService.getItem(3), rows: [], more: false };
          let exist = this.listService.find(this._searchresult, 'type', 1);
          if (exist) {
            groupresult = exist;
          }

          res.Levels.forEach((item) => {
            let groupitem: GroupItem = { id: item.Id, path: '/levels/', description: item.Path + item.Name };
            groupresult.rows.push(groupitem);
            this._total++;
          });

          groupresult.more = res.More;
          if (res.More) {
            this._more = true;
            this._all = !first;
          }

          if (!exist) {
            this._searchresult.push(groupresult);
          }
        }

      });
  }
  private searchObjects(top, searchstr, first, category) {

    let filter = {
      Search: searchstr,
      Category: category.Key,
      Top: top,
      Multiple: this._multiple
    };

    this.dataService.tokenRequest('/api/v1/objects/search', 'POST', filter)
      .subscribe(res => {

        if (res.Objects.length > 0) {

          let type = category.Key * -1;

          let groupresult: GroupResult = { type: type, header: category.Value, rows: [], more: false };
          let exist = this.listService.find(this._searchresult, 'type', type);
          if (exist) {
            groupresult = exist;
          }

          res.Objects.forEach((item) => {
            let groupitem: GroupItem = { id: item.Id, path: '/objects/' + category.Key + '/', description: item.Header };
            groupresult.rows.push(groupitem);
            this._total++;
          });

          groupresult.more = res.More;
          if (res.More) {
            this._more = true;
            this._all = !first;
          }

          if (!exist) {
            this._searchresult.push(groupresult);
          }
        }

      });
  }

  private resetSearch() {
    this._isSearching = false;
    this.searchstring = '';
    this._current = -1;
  }

}

//Interfaces
export interface GroupResult {
  type: number;
  header: string;
  rows: GroupItem[];
  more: boolean;
}
export interface GroupItem {
  id: number;
  path: string;
  description: string;
}
