<swe-card [header]="languageService.getItem(134)" [open]="open" (refreshChange)="search(false)" (openChange)="search(false)" [rulelist]="['SweHasNews']">
  <span swe-rightcommand><i class="bi bi-plus-lg bi-swe-pull-right swe-click" (click)="goto(0)" *ngIf="permissionService.permissions.News>1"></i></span>
  <swe-adminrule [rulelist]="['SweNewsLevels']"></swe-adminrule>
  <div class="list-group">
    <ng-container *ngFor="let item of news">
      <div class="list-group-item" [ngClass]="{'list-group-item-warning': item.NotPublished,'list-group-item-danger': item.Expired}" *ngIf="(showpublic || !item.IsPublic)">
        <!--List mode-->
        <div class="d-flex flex-wrap" *ngIf="!item.isedit && item.Id>0 && (showpublic || !item.IsPublic)"
             [ngClass]="{'swe-click': (item.Access>1||(permissionService.permissions.NewsAll>0&&item.LevelId==0))}"
             (click)="(item.Access>1||(permissionService.permissions.NewsAll>0&&item.LevelId==0))&&goto(item.Id)">
          <div class="col-12 col-lg-2">
            <img class="swe-circle-image" alt="{{item.Title}}" *ngIf="item.Image.length>0" sweImage imageFolder="announcementimages" [imageFile]="item.Image" imageWidth="60" imageHeight="60" />
          </div>
          <div class="col-12 col-lg-10 d-flex flex-wrap">
            <div class="col-12 col-md-6"><b>{{item.Title}}</b></div>
            <div class="col-12 col-md-6"><i>(<span *ngIf="item.Role">{{item.Role}}&nbsp;</span>{{item.Level}})</i></div>
            <div class="col-12 col-md-12" [innerHTML]="wash(item.Body)"></div>
            <div class="col-12 col-md-12" *ngIf="item.Link.length>0"><a class="text-primary swe-click" (click)="openLink(item, $event)">{{item.LinkName}}</a></div>
            <div class="col-12 col-md-12"><i>{{dateTimeService.format(item.Publish)}}</i></div>
          </div>
        </div>
      </div>
    </ng-container>
    <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
  </div>
</swe-card>
