import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';
import { PermissionService } from '../_services/permission.service';
import { ListService } from '../_services/list.service';
import { DateTimeService } from '../_services/datetime.service';
import { SettingService } from '../_services/setting.service';
import { AlertService } from '../_services/alert.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { ListUtility } from '../_utilities/list.utility';


@Component({
  selector: 'app-timereports',
  templateUrl: './timereports.component.html'
})
export class TimereportsComponent implements OnInit, OnDestroy {

  private unsubsribe$ = new Subject<void>();
  private _timereports = [];
  private _groupedby = {};
  private _more: boolean = false;
  private _all: boolean = false;
  private _multiple: number = 1;
  private _hits: number = 0;
  private _loading: boolean = false;
  //private _checkcounter: number = 0;
  //private _ischecked: boolean = false;
  private _actionmenu: boolean = false;
  //private _toggleaccess: boolean = false;
  private _listutility: ListUtility = new ListUtility();

  constructor(public languageService: LanguageService, public permissionService: PermissionService, private dataService: DataService, private dateTimeService: DateTimeService, private listService: ListService, private settingService: SettingService, private alertService: AlertService, private viewCacheService: ViewCacheService, private router: Router) {

    settingService.initView(
      1 | 2 | 16 | 512,
      ['User', 'Booking', 'BookingUser'],
      [
        { type: 'booking', key: 'intervaltype' },
        { type: 'booking', key: 'shifttype' },
        { type: 'booking', key: 'reservation' },
        { type: 'booking', key: 'replaceableasnoslot' },
        { type: 'booking', key: 'activityoption' }
        /*,{ type: 'booking', key: 'owner' }*/
      ]);

    settingService.onRefresh$
      .pipe(takeUntil(this.unsubsribe$))
      .subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy() {
    this.unsubsribe$.next();
    this.unsubsribe$.complete();
  }

  ngOnInit() {

    this._listutility.toggleaccess = (this.permissionService.permissions.MultiTimereport > 0);

    this.search();
  }

  /*Properties*/
  public get groupedby() {
    return this._groupedby;
  }
  public get timereports() {
    return this._timereports;
  }
  public get more() : boolean {
    return this._more;
  }
  public get all(): boolean {
    return this._all;
  }
  public get hits(): number {
    return this._hits;
  }
  public get loading(): boolean {
    return this._loading;
  }
  //public get ischecked() {
  //  return this._ischecked;
  //}
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  //public get toggleaccess() {
  //  return this._toggleaccess;
  //}
  public get listutility() {
    return this._listutility;
  }


  public handleSearchEvent($event) {
    this.search($event.getMore, $event.getAll);
  }

  /*Methods*/
  public search(getmore: boolean = false, all: boolean = false) {

    this._all = false;

    let top = 25;
    if (typeof all != "undefined" && all) {
      top = 0;
    }

    if (getmore) {
      this._multiple++;
    }
    else {
      this._multiple = 1;
      this._timereports = [];
    }

    let filter = {
      Top: top,
      Multiple: this._multiple,
      //Bookings
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking'),
      StatusList: this.settingService.booking.status,
      Personal: this.settingService.booking.personal,
      CheckIn: this.settingService.booking.checkin,
      TypeList: this.settingService.booking.bookingtype.join(),
      OwnerId: this.settingService.booking.owner,
      ShiftLevelGroups: this.settingService.booking.levelgroups.join(),
      ShiftLevelList: this.settingService.levelList(2),
      //Users
      Search: this.settingService.user.search,
      UserList: this.settingService.userList(),
      RoleList: this.settingService.user.roles.join(),
      Active: this.settingService.user.activity,
      Booking: this.settingService.user.bookingtype,
      UserLevelGroups: this.settingService.user.levelgroups.join(),
      UserLevelList: this.settingService.levelList(1),
      //Timereports
      RemoveStandby: true,
      RemoveDecline: true,
      //Properties
      PropertyList: this.settingService.property.properties,
      UseOR: this.settingService.property.useor
    };

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/timereports/search', 'POST', filter)
      .subscribe(res => {
        if (res) {

          res.TimeReports.forEach(timereport => {

            timereport.dateheader = this.dateTimeService.format(timereport.ShiftStart, 'HH:mm') + ' - ' + this.dateTimeService.format(timereport.ShiftEnd, 'HH:mm');

            timereport.partheader = [];
            if (timereport.StartTimeType.length > 0) {
              let date1 = timereport.Start < timereport.ShiftStart ? timereport.Start : timereport.ShiftStart;
              let date2 = timereport.Start < timereport.ShiftStart ? timereport.ShiftStart : timereport.Start;
              timereport.partheader.push(this.dateTimeService.format(date1, 'HH:mm') + ' - ' + this.dateTimeService.format(date2, 'HH:mm') + ' ' + timereport.StartTimeType);
            }
            if (timereport.TimeType.length > 0) {
              let date1 = timereport.ShiftStart > timereport.Start ? timereport.ShiftStart : timereport.Start;
              let date2 = timereport.ShiftEnd < timereport.End ? timereport.ShiftEnd : timereport.End;
              timereport.partheader.push(this.dateTimeService.format(date1, 'HH:mm') + ' - ' + this.dateTimeService.format(date2, 'HH:mm') + ' ' + timereport.TimeType);
            }
            else {
              timereport.partheader.push(this.dateTimeService.format(timereport.Start, 'HH:mm') + ' - ' + this.dateTimeService.format(timereport.End, 'HH:mm'));
            }
            if (timereport.EndTimeType.length > 0) {
              let date1 = timereport.End > timereport.ShiftEnd ? timereport.ShiftEnd : timereport.End;
              let date2 = timereport.End > timereport.ShiftEnd ? timereport.End : timereport.ShiftEnd;
              timereport.partheader.push(this.dateTimeService.format(date1, 'HH:mm') + ' - ' + this.dateTimeService.format(date2, 'HH:mm') + ' ' + timereport.EndTimeType);
            }

            this._timereports.push(timereport);
          });

          this._listutility.rows = this._timereports;
          this._hits = this._timereports.length;
          this._more = res.More;
          this._all = (res.More && this._timereports.length > 0);

          this._groupedby = this.listService.groupByDate(this._timereports, "Start");
        }

        this._loading = false;
      });
  }
  public change = function (timereport, status, e) {

    e.stopPropagation();

    let diffstatus = (timereport.Status - status);

    if (diffstatus >= 0) {
      //removed downgrading in #496
      return
    }
    
    //Upgrade
    if (this.getAccess(status, timereport) < 2) {
      this.raiseAccessError(status, false);
      return;
    }
    

    let dto = {
      Status: status,
      StartTimeType: -1, //Not changed
      TimeType: -1, //Not changed
      EndTimeType: -1 //Not changed
    };

    this.dataService.tokenRequest('/api/v1/timereports/' + timereport.Id, 'PUT', dto, 'text')
      .subscribe(res => {
        if (res) {
          timereport.Status = status;
          this.alertService.Add({ message: res, type: 'success' });
        }
      });

  }
  public checkin(id, access, e) {

    e.stopPropagation();

    if (access < 2) {
      this.alertService.Add({ message: this.languageService.getItem(207), type: 'danger' });
      return;
    }

    let obj = this.listService.find(this._timereports, 'Id', id);
    
    let dto = {
      Id: id,
      CheckIn: true
    };

    this.dataService.tokenRequest('/api/v1/timereports/checkincheckout/', 'POST', dto, 'text')
      .subscribe(res => {
        obj.CheckIn = 1;
        this.alertService.Add({ message: res, type: 'success' });
      });
  }
  public checkout(id, access, e) {

    e.stopPropagation();

    if (access < 3) {
      this.alertService.Add({ message: this.languageService.getItem(208), type: 'danger' });
      return;
    }

    let obj = this.listService.find(this._timereports, 'Id', id);

    let dto = {
      Id: id,
      CheckOut: true
    };

    this.dataService.tokenRequest('/api/v1/timereports/checkincheckout/', 'POST', dto, 'text')
      .subscribe(res => {
        obj.CheckIn = 2;
        this.alertService.Add({ message: res, type: 'success' });
      });
  }
  public create(type: number, e) {

    let url = '/timereports/0/user/' + this.permissionService.user.Id + '/type/' + type;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public open(id: number, e) {

    let timereports = [];
    this._timereports.forEach((timereport) => {
      timereports.push(timereport.Id);
    });
    this.viewCacheService.add('timereports', timereports);

    let url = '/timereports/' + id;

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public action(option) {

    let timereports = [];
    this._timereports.forEach((timereport) => {
      if (timereport.checked && timereport.Access > 1) {
        timereports.push(timereport.Id);
      }
    });

    if (timereports.length > 0) {

      this.viewCacheService.add('multi_timereports', timereports);

      this.router.navigate(['/timereports/action/' + option + '/']);
    }
  }


  //Check Users
  //public toggle(row, e) {

  //  e.stopPropagation();

  //  if (!this._toggleaccess || row.Access < 2) {
  //    return;
  //  }

  //  row.checked = !row.checked;

  //  this._checkcounter = this._checkcounter + (row.checked ? 1 : -1);

  //  this._ischecked = this._checkcounter > 0;
  //}
  //public checkall(option) {
  //  this._timereports.forEach((timereport) => {
  //    if (timereport.Access > 1) {
  //      timereport.checked = option;
  //    }
  //  });

  //  this._checkcounter = option ? this._timereports.length : 0;
  //  this._ischecked = option;
  //}



  //Functions
  private getAccess(status, obj) {
    if (status == 40) {
      return obj.TimereportAccess;
    }
    else if (status == 50) {
      return obj.ConfirmedAccess;
    }
    else if (status == 60) {
      return obj.CompletedAccess;
    }

    return 0;
  }
  private raiseAccessError(status, downgrade) {
    if (downgrade) {
      let msg = (status == 60) ? this.languageService.getItem(458) : ((status == 50) ? this.languageService.getItem(457) : this.languageService.getItem(456));
      this.alertService.Add({ message: msg, type: 'danger' });
      return;
    }
    else {
      let msg = (status == 60) ? this.languageService.getItem(230) : ((status == 50) ? this.languageService.getItem(229) : this.languageService.getItem(228));
      this.alertService.Add({ message: msg, type: 'danger' });
      return;
    }
  }

}
