<swe-pageheader [header]="languageService.getItem(3)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<swe-fixedheader [more]="more" [listutility]="listutility" [listoptions]="listoptions" (searchEvent)="handleSearchEvent($event)">
  <ng-template #listoptions>

      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" [attr.disabled]="listutility.ischecked ? null : true">
        <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiLevel>0"><a (click)="action('multi')"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(655)}}</a></li>
        <li class="dropdown-divider" *ngIf="permissionService.permissions.MultiLevel>0&&permissionService.permissions.Email>0"></li>
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.Email>0"><a (click)="send(1)"><i class="bi bi-swe-fw bi-envelope-fill"></i>&nbsp;{{languageService.getItem(321)}}</a></li>
      </ul>
  </ng-template>
</swe-fixedheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" [rulelist]="['SweLevels']">
      <span swe-rightcommand *ngIf="permissionService.permissions.Levels>1">
        <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="open(0, $event)"></i>
      </span>
      <swe-list [data]="levels" [loading]="loading">
        <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-warning swe-scroll-to': level.Id==settingService.lastlevel&&!level.checked, 'list-group-item-info': level.checked}" *ngFor="let level of levels" (click)="open(level.Id, $event)">
          <div class="col-3 col-md-1" (click)="listutility.toggle(level, $event)">
            <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="level.checked">
              <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
            </span>
            <img class="swe-circle-image" alt="{{level.Name}}" sweImage imageFolder="levelimages" [imageFile]="level.Image" imageWidth="32" imageHeight="32" *ngIf="!level.checked&&level.Image.length>0" />
            <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="!level.checked&&level.Image.length==0">
              <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
              <span class="bi-swe-stack-1x bi-swe-letter">{{level.Name[0]}}</span>
            </span>
          </div>
          <div class="col-9 col-md-11 d-flex flex-wrap">
            <div class="col-12 col-md-6"><span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}</div>
            <div class="col-12 col-md-3">{{level.Group}}</div>
            <div class="col-12 col-md-3">{{level.Email}}</div>
          </div>
        </a>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
