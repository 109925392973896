import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { LanguageService } from '../_services/language.service';
import { SettingService } from '../_services/setting.service';

@Component({
  selector: 'swe-card',
  templateUrl: 'card.component.html',
})
export class CardComponent implements OnInit, OnChanges {
  @Input() uniqueId: string = '';
  @Input() header: string = '';
  @Input() headerDetail: string;
  @Input() hideHeaderOnDesktop: boolean;
  @Input() rulelist: string[];
  @Input() color: string = '';
  @Input() hasCollapse: boolean = true;
  @Input() hasRefresh: boolean = true;
  @Input() hasZoom: boolean = false;
  @Output() refreshChange = new EventEmitter<any>();
  @Input() tabs: Tab[] = [];
  @Input() active: number = 0;
  @Output() activeChange = new EventEmitter<any>();
  @Input() stickyTop: number = -1;
  @Input() focus: boolean = false;
  @Input() fitcontent: boolean = false;
  @Input() bigheader: boolean = false;
  @Input() icon: string;
  //Edit
  @Input() hasEditMode: boolean = false;
  //Save
  @Input() hasSave: boolean = false;
  @Output() saveChange = new EventEmitter<any>();
  //Close
  @Input() hasClose: boolean = false;
  @Input() maxZoom: number = 1;
  @Output() closeChange = new EventEmitter<any>();
  //Open
  @Input() open: boolean = true;
  @Input() rememberopen: boolean = false;
  @Output() openChange = new EventEmitter<any>();

  @ViewChild('sweFocusElement1') focusElement1: ElementRef;
  @ViewChild('sweFocusElement2') focusElement2: ElementRef;

  private _refreshing: boolean = false;
  private _bordercolor: string = 'border-light';
  private _titlecolor: string = '';
  private _stickyTopCss: string = '';
  private _zoom: number = 1;
  private _zoomInput: number = 1;
  private _editMode: boolean = false;

  constructor(public languageService: LanguageService, public settingService: SettingService) {

  }

  ngOnInit() {
    
    if (this.color && this.color.length > 0) {
      this._bordercolor = 'border-' + this.color;
      this._titlecolor = 'text-' + this.color;
    }

    if (this.stickyTop && this.stickyTop > -1) {
      this._stickyTopCss = "sticky-top swe-sticky-top";
      if (this.stickyTop > 0) {
        this._stickyTopCss += "-" + this.stickyTop;
      }
    }

    if (this.focus) {
      setTimeout(() => {
        if (this.focusElement1) {
          let element = this.focusElement1.nativeElement;
          element.focus();
        }
        else if (this.focusElement2) {
          let element = this.focusElement2.nativeElement;
          element.focus();
        }
      }, 0); //Create a macrotask that will run in the next VM turn
    }

    if (this.hasEditMode) {
      this._editMode = this.settingService.editMode;
    }
    else {
      this._editMode = true;
    }

    if (this.rememberopen) {
      this.open = this.settingService.isCardOpen(this.uniqueId ? this.uniqueId : this.header);
    }
  }
  ngOnChanges() {
    if (!this.hasEditMode) {
      this._editMode = true;
    }
  }

  /*Properties*/
  public get isOpen(): boolean {
    return this.open;
  }
  public get isRefreshing(): boolean {
    return this._refreshing;
  }
  public get bordercolor(): string {
    return this._bordercolor;
  }
  public get titlecolor(): string {
    return this._titlecolor;
  }
  public get stickyTopCss(): string {
    return this._stickyTopCss;
  }
  public set zoomInput(val: number) {
    this._zoomInput = val;
  }
  public get zoomInput() {
    return this._zoomInput;
  }

  public get editMode() {
    return this._editMode;
  }

  public get zoom() {
    return this._zoom;
  }

  /*Methods*/
  public toggle() {
    this.open = !this.open;
    if (this.rememberopen) {
      this.settingService.toggleCardOpen(this.uniqueId ? this.uniqueId : this.header);
    }
    this.openChange.emit(this.open);
  }
  public refresh(e) {

    e.stopPropagation();

    this._refreshing = true;
    this.refreshChange.emit(true);

    setTimeout(() => {
      this._refreshing = false;
    }, 1000);
  }
  public save(e) {

    this.saveChange.emit(e);
    e.stopPropagation();
  }
  public close(e) {

    this.closeChange.emit(e);
    e.stopPropagation();
  }
  public change(index: number, e) {

    e.stopPropagation();

    if (this.active != index) {
      this.active = index;
      this.activeChange.emit(this.active);
    }
  }
  public changeMode(e) {
    e.stopPropagation();
    this.settingService.changeEditMode();
    this._editMode = this.settingService.editMode;
  }
  public zoomChanged() {
    this._zoom = this._zoomInput;  
  }
}

export class Tab {
  public id: number;
  public name: string;
}
