import { Injectable } from '@angular/core';

import { DateTimeService } from './datetime.service';

@Injectable({ providedIn: 'root' })
export class ListService {

  constructor(private dateTimeService: DateTimeService) {

  }

  //Group
  public groupByDate(array: any[], key:string) {

    let groupedBy = {};
    let l;
    if (array) {

      let today = this.dateTimeService.format(new Date(), 'yyyy-MM-dd');

      for (var i = 0; l = array.length, i < l; i++) {
        let date = new Date(array[i][key]);

        let groupby = this.dateTimeService.format(date, 'yyyy-MM-dd');
        let groupname = this.dateTimeService.weekdayname(date,'') + " " + date.getDate() + " " + this.dateTimeService.monthname(date);

        groupedBy[groupby] = groupedBy[groupby] || {};
        groupedBy[groupby]["header"] = groupname;
        groupedBy[groupby]["today"] = (groupby == today);
        groupedBy[groupby]["items"] = groupedBy[groupby]["items"] || [];
        groupedBy[groupby]["items"].push(array[i]);
      }
    }

    return groupedBy;
  }
  public groupByInt(array: any[], key: string, grouplist: any[], namekey: string = '') {

    //Not an array, maybe already converted, return input
    if (!Array.isArray(array)) { return array };

    let groupedBy = {};
    let l;
    if (array) {

      for (var i = 0; l = array.length, i < l; i++) {
        let int = array[i][key];

        var groupby = int;
        var groupname = '';
        if (grouplist) {
          grouplist.forEach((group) => {

            if (group.Id == int) {
              groupname = group.Name;
              if (typeof group.Sort != "undefined") {
                groupby = group.Sort;
              }
            }

          });
        }
        else if (namekey.length > 0) {
          groupname = array[i][namekey];
        }

        groupedBy[groupby] = groupedBy[groupby] || {};
        groupedBy[groupby]["header"] = groupedBy[groupby]["header"] || '';
        groupedBy[groupby]["header"] = groupname.length > 0 ? groupname : groupedBy[groupby]["header"];
        groupedBy[groupby]["items"] = groupedBy[groupby]["items"] || [];
        groupedBy[groupby]["items"].push(array[i]);
      }
    }

    return groupedBy;
  }

  //Format
  public formatArray(haystack, needle, key) {
    let result = [];

    if (haystack != null) {
      let property = 'Id';
      if (key) {
        property = key;
      }

      if (needle) {
        let item, i;
        for (let i = 0; i < haystack.length; i++) {
          item = haystack[i];
          if (needle.indexOf(item[property]) !== -1)
            result.push(item);
        };
      }
    }

    return result;
  }

  //Find
  public find(array, key, val, remove: boolean = false) {

    //IE 11 not supported
    //return array.find(p => p[key] === val);

    let found = null;
    let index = -1;
    array.forEach((item, idx) => {
      if (found == null && (item[key] == val)) {
        found = item;
        index = idx;
      }
    });

    if (remove && index > -1) {
      array.splice(index, 1);
    }

    return found;
  }


  //Sort
  public sort(array: any[], sortBy: any[], asc: boolean) {

    array.sort((a, b) => {
      // a and b will here be two objects from the array
      // thus a[col] and b[col] will equal the names

      return this.sortItem(a, b, sortBy, 0, asc);
    });
  }
  private sortItem(a: any, b: any, sortBy: any[], index: number, asc: boolean) {
    let sort = sortBy[index];
    let valueA = a[sort];
    let valueB = b[sort];
    if (valueA == valueB) {
      // if they are equal, return 0 (no sorting)
      if ((index + 1) < sortBy.length) {
        return this.sortItem(a, b, sortBy, ++index, asc);
      }
      return 0;
    }
    else if (valueA > valueB) {
      // if a should come after b, return 1
      return asc ? 1 /*asc*/ : -1 /*desc*/;
    }
    else {
      // if b should come after a, return -1
      return asc ? - 1 /*asc*/ : 1 /*desc*/;
    }
  }
}
