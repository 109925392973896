<swe-pageheader [header]="languageService.getItem(51)"><span swe-hits>&nbsp;({{hits}}<span *ngIf="more">+</span>)</span></swe-pageheader>
<swe-fixedheader [datePagerOpt]="{hideTime: true, type: 'usermarkeddate'}" [listoptions]="listoptions" [listutility]="listutility" [all]="all" (searchEvent)="handleSearchEvent($event);" [access]="permissionService.permissions.MultiMarkedDate">
  <ng-template #listoptions>
      <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" [attr.disabled]="listutility.ischecked ? null : true">
        <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
        <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiMarkedDate>0"><a (click)="action('multi')"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(670)}}</a></li>
      </ul>
  </ng-template>
</swe-fixedheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [hasCollapse]="false" [hasRefresh]="true" (refreshChange)="search()" [rulelist]="['SweUserMarkedDates']">
      <span swe-rightcommand *ngIf="permissionService.permissions.MarkedDates>1">
        <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="open(0, $event)"></i>
      </span>
      <swe-list [data]="usermarkeddates" [loading]="loading">
        <a href="javascript:void(0);" class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-info': umd.checked}" *ngFor="let umd of usermarkeddates" (click)="(umd.Access>0)&&open(umd.Id, $event)">
          <div class="col-3 col-md-1" (click)="listutility.toggle(umd, $event)">
            <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="umd.checked">
              <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
            </span>
            <img class="swe-circle-image" alt="{{umd.Name}}" sweImage imageFolder="userimages" [imageFile]="umd.Image" imageWidth="32" imageHeight="32" *ngIf="!umd.checked&&umd.Image.length>0" />
            <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="!umd.checked&&umd.Image.length==0">
              <i class="bi bi-circle-fill bi-swe-stack-2x"></i>
              <span class="bi-swe-stack-1x bi-swe-stack-1x bi-swe-letter">{{umd.Name[0]}}</span>
            </span>
          </div>
          <div class="col-9 col-md-11 d-flex flex-wrap">
            <div class="col-12 col-md-2">{{umd.dateheader}}</div>
            <div class="col-12 col-md-3">{{umd.Name}}</div>
            <div class="col-12 col-md-5">{{umd.Comment}}</div>
            <div class="col-12 col-md-2"><div style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': umd.Color}"></div>&nbsp;{{umd.Type}}</div>
          </div>
        </a>
        <button class="btn btn-primary" *ngIf="more" (click)="search(true)">{{languageService.getItem(57)}}</button>
      </swe-list>
    </swe-card>
  </div>
</div>
