import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LanguageService } from '../_services/language.service';
import { PermissionService } from '../_services/permission.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { DateTimeService } from '../_services/datetime.service';
import { ListService } from '../_services/list.service';
import { GeneralService } from '../_services/general.service';




@Component({
  selector: 'swe-generalarticles',
  templateUrl: './generalarticles.component.html'
})
export class GeneralArticlesComponent implements OnInit, OnChanges, OnDestroy {

  @Input() id: number = 0;
  @Input() header: string = '';
  @Input() open: boolean;
  @Input() cardcolor: string;
  @Input() idList: any[] = [];
  @Input() isoverview: boolean = false;
  @Input() isuser: boolean = false;
  @Input() islevel: boolean = false;
  @Input() salaryaccess: number = 0;
  @Input() invoiceaccess: number = 0;

  private _subscriptions: Subscription[] = [];
  private _groupedby: any = {};
  private _articles: any[] = [];
  private _loading: boolean = false;
  private _sumsalary: number = 0;
  private _suminvoice: number = 0;
  private _tb: number = 0;
  private _tg: number = 0;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public settingService: SettingService,
    public dateTimeService: DateTimeService,
    private generalService: GeneralService,
    private dataService: DataService,
    private listService: ListService
  ) {
    this._subscriptions.push(settingService.onViewRefresh$
      .subscribe((refresh) => {

        if (refresh.type == 'reload_articles') {
          if (this.open) {
            this.load();
          }
        }

      }));

    this._subscriptions.push(settingService.onRefresh$
      .subscribe(() => {
        this.load();
      }));
  }


  ngOnInit() {
    
  }

  ngOnChanges() {
    if (this.open) {
      this.load();
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }


  //Properties
  public get groupedby() {
    return this._groupedby;
  }
  public get articles() {
    return this._articles;
  }
  public get isloading() {
    return this._loading;
  }
  public get sumsalary() {
    return this._sumsalary;
  }
  public get suminvoice() {
    return this._suminvoice;
  }
  public get tb() {
    return this._tb;
  }
  public get tg() {
    return this._tg;
  }
  


  //Methods
  public load() {
    
    this._loading = true;

    let url = '/api/v1/timereports/' + this.id + '/articles/';
    let verb = 'Get';
    let dto = {};
    if (this.isoverview) {
      //Overview
      url = '/api/v1/articles/search/';
      verb = 'Post';
      dto = {
        Start: this.settingService.start('booking'),
        End: this.settingService.end('booking')
      };

      //User
      dto['UserFrameworkContracts'] = this.settingService.user.frameworkcontracts;
      dto['UserContract'] = this.settingService.user.contract;
      dto['UserContracts'] = this.settingService.user.contracts;
      dto['UserLevelGroups'] = this.settingService.user.levelgroups.join();
      dto['UserLevelList'] = this.settingService.levelList(1);

      //Levels
      dto['LevelFrameworkContracts'] = this.settingService.level.frameworkcontracts;
      dto['LevelContract'] = this.settingService.level.contract;
      dto['LevelContracts'] = this.settingService.level.contracts;
      dto['LevelGroups'] = this.settingService.level.levelgroups.join();
      dto['LevelList'] = this.settingService.levelList(3);
    }
    else if (this.isuser) {
      //User
      url = '/api/v1/users/' + this.id + '/articles/';
      verb = 'Post';
      dto = {
        Start: this.settingService.start('booking'),
        End: this.settingService.end('booking'),
        IdList: this.idList //TimereportIdList
      };
    }
    else if (this.islevel) {
      //User
      url = '/api/v1/levels/' + this.id + '/articles/';
      verb = 'Post';
      dto = {
        Start: this.settingService.start('booking'),
        End: this.settingService.end('booking'),
        IdList: this.idList //BookingIdList
      };
    }
    
    this.dataService.tokenRequest(url, verb, dto)
      .subscribe((res) => {

        this._articles = res;

        let totalsalary = 0;
        let totalinvoice = 0;
        this.articles.forEach((article) => {
          article.Sum = article.Quantity * article.Price;
          if (article.Type == 1) {
            //Salary
            article.ExtraEmployerFee = 0;
            if (article.EmployerFee > 0) {
              article.ExtraEmployerFee = article.Sum * article.EmployerFee / 100;
            }
            article.ExtraVacationPercentage = 0;
            if (article.VacationPercentage > 0) {
              article.ExtraVacationPercentage = article.Sum * article.VacationPercentage / 100;
            }
            article.ExtraPensionPercentage = 0;
            if (article.PensionPercentage > 0) {
              article.ExtraPensionPercentage = article.Sum * article.PensionPercentage / 100;
            }
            article.ExtraGeneralPercentage = 0;
            if (article.GeneralPercentage > 0) {
              article.ExtraGeneralPercentage = article.Sum * article.GeneralPercentage / 100;
            }
            article.ExtraCost = 0;
            if (article.Cost > 0) {
              article.ExtraCost = article.Sum * article.Cost / 100;
            }
            article.Total = article.Sum + article.ExtraCost;
            totalsalary += article.Total;
          }
          else {
            //Invoice
            article.Total = article.Sum
            totalinvoice += article.Sum;
          }
          article.Quantity = this.generalService.formatdecimal(article.Quantity);
          article.Price = this.generalService.formatdecimal(article.Price);
          article.Sum = this.generalService.formatdecimal(article.Sum);
          article.ExtraEmployerFee = this.generalService.formatdecimal(article.ExtraEmployerFee);
          article.ExtraVacationPercentage = this.generalService.formatdecimal(article.ExtraVacationPercentage);
          article.ExtraPensionPercentage = this.generalService.formatdecimal(article.ExtraPensionPercentage);
          article.ExtraGeneralPercentage = this.generalService.formatdecimal(article.ExtraGeneralPercentage);
          article.ExtraCost = this.generalService.formatdecimal(article.ExtraCost);
          article.Total = this.generalService.formatdecimal(article.Total);
        });
        this._sumsalary = this.generalService.formatdecimal(totalsalary);
        this._suminvoice = this.generalService.formatdecimal(totalinvoice);
        this._tb = this.generalService.formatdecimal(this._suminvoice - this._sumsalary);
        if (this.suminvoice > 0) {
          this._tg = this.generalService.formatdecimal((this._tb * 100) / this.suminvoice);
        }
        else {
          this._tg = 0;
        }

        this._groupedby = this.listService.groupByInt(
          this._articles,
          'Type',
          [
            { Id: 1, Name: this.languageService.getItem(1004), Sort: 0 },
            { Id: 2, Name: this.languageService.getItem(1005), Sort: 1 }
          ]);

        this._loading = false;
      });
    

  }
  



  //Functions
  
}
