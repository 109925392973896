import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { DataService } from '../../_services/data.service';
import { SettingService } from '../../_services/setting.service';
import { AlertService } from '../../_services/alert.service';



@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html'
})
export class ArticlesComponent implements OnInit {

  private _articles: any[] = [];
  private _loading: boolean = false;
  private _open: boolean = false;

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    private dataService: DataService,
    private alertService: AlertService,
    private settingService: SettingService,
    private router: Router
  ) {
    //Reset
    settingService.initView(
      2 | 4,
      [],
      [
        { type: 'user', key: 'search' },
        { type: 'user', key: 'roles' },
        { type: 'user', key: 'activity' },
        { type: 'user', key: 'bookingtype' },
        { type: 'user', key: 'employment' }
      ]
    );
  }


  ngOnInit() {

    this._open = this.settingService.isCardOpen('articles', this._open, true);

    this.load();

  }



  //Properties
  public get articles() {
    return this._articles;
  }
  public get open() {
    return this._open;
  }
  public get isloading() {
    return this._loading;
  }




  //Methods
  public load() {

    this._loading = true;

    this.dataService.tokenRequest('/api/v1/articles', 'GET', {})
      .subscribe((res) => {

        this._articles = res;

        this._loading = false;
      });
  }
  public goto(id) {
    this.router.navigate(['/admin/articles', id]);
  }
  public sort(index, moveUp, e) {

    e.stopPropagation();

    if (index == 0 && moveUp) { return; }
    if (index == this.articles.length - 1 && !moveUp) { return; }

    let otherIndex = moveUp ? (index - 1) : (index + 1);

    let currentArticle = this.articles[index];
    let otherArticle = this.articles[otherIndex];

    if (currentArticle.Sort == otherArticle.Sort) {
      //Separate sort position
      currentArticle.Sort = currentArticle.Sort + (moveUp ? -1 : 1);
      otherArticle = null;
    }
    else {
      //Switch sort position
      let tmpSort = currentArticle.Sort;
      currentArticle.Sort = otherArticle.Sort;
      otherArticle.Sort = tmpSort;
    }

    this.dataService.tokenRequest('/api/v1/articles/' + currentArticle.Id, 'Put', currentArticle, 'text', 'response')
      .subscribe((response) => {

        if (otherArticle != null) {
          this.dataService.tokenRequest('/api/v1/articles/' + otherArticle.Id, 'Put', otherArticle, 'text', 'response')
            .subscribe((response2) => {

              this.alertService.Add({ type: 'success', message: response2.body });
              this.load();

            });
        }
        else {
          this.alertService.Add({ type: 'success', message: response.body });
          this.load();
        }

      });

  }




}
