import { Injectable } from '@angular/core';

import { Observable, EMPTY, forkJoin, of } from 'rxjs';
import { catchError, shareReplay, take, switchMap } from 'rxjs/operators';

import { DataService } from './data.service';
import { SettingService } from './setting.service';
import { Local } from 'protractor/built/driverProviders';



@Injectable({ providedIn: 'root' })
export class PermissionService  {

  private _permissions: any;
  private _unauthpermissions: any;
  private _user: any

  
  constructor(
    private dataService: DataService,
    private settingService: SettingService
  ) {
    
  }

  resolve() {

    return forkJoin(
      this.loadPermissions(),
      this.loadUser()
    ).pipe(
      take(1),
      switchMap(res => {
        this.settingService.SearchLocked = this._permissions.SearchLocked;
        return res;
      })
    );
  }

  

  //Methods
  public isLocked(value: number) {
    if (this._permissions != null && this._permissions.SearchLocked != null) {
      return this._permissions.SearchLocked.indexOf(value) > -1;
    }

    return true;
  }
  public isimpersonate() {

    let originaluser = localStorage.getItem('originaluser');

    return originaluser ? parseInt(originaluser) : 0;
  }
  public hasObject(id: number, generalId: number = 0) {

    if (this._permissions != null && this._permissions.Objects != null) {
      let objPermission = this._permissions.Objects.filter(function (o) { return o.Key == id; });
      if (objPermission && objPermission.length > 0) {
        if (objPermission[0].Extra == '1') {
          //This object is to be seen only for yourself
          if (this._user.Id == generalId) {
            return objPermission[0].Value;
          }
          return 0;
        }
        return objPermission[0].Value;
      }
    }

    return 0;
  }



  //Reload
  public reload(): Observable<any> {
    return forkJoin(
      this.reloadPermissions(),
      this.reloadUser()
    ).pipe(
      take(1),
      switchMap(res => {

        return res;
      })
    );
  }
  public reloadPermissions(): Observable<any> {
    this._permissions = null;
    return this.loadPermissions();
  }
  public reloadUser(): Observable<any> {
    this._user = null;
    return this.loadUser();
  }



  //Properties
  public get permissions() {
    return this._permissions;
  }
  public get unauthpermissions() {

    if (!this._unauthpermissions) {
      this._unauthpermissions = {};

      this.dataService.basicRequest('/api/v1/general/permissions/unauthorize', 'GET').subscribe(res => {
        if (res) {
          this._unauthpermissions = res;
        }
      });
    }

    return this._unauthpermissions;
  }
  public get user() {
    return this._user;
  }



  private loadPermissions() {
    if (!this._permissions) {

      this._permissions = {};

      return this.dataService.tokenRequest('/api/v1/general/permissions', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {

            this._permissions = res;

            let searchavailable = Math.pow(2, res.SearchAvailable);

            //Defaults
            this.settingService.defaultbooking.start = this.settingService.booking.start = new Date(res.SearchStart);
            this.settingService.defaultbooking.end = this.settingService.booking.end = new Date(res.SearchEnd);
            this.settingService.defaultbooking.bookingtype = this.settingService.booking.bookingtype = res.SearchBookingType || [];
            this.settingService.defaultbooking.shifttype = this.settingService.booking.shifttype = res.SearchShiftType;
            this.settingService.defaultbooking.reservation = this.settingService.booking.reservation = res.SearchReservation;
            this.settingService.defaultbooking.personal = this.settingService.booking.personal = res.SearchOnlyYours;
            this.settingService.defaultbooking.availability = this.settingService.booking.availability = ((res.AvailableType & searchavailable) == searchavailable) ? res.SearchAvailable : 0;

            this.settingService.defaulttimeline.groupby = this.settingService.timeline.groupby = res.SearchGroupby;
            this.settingService.defaulttimeline.exactrendering = this.settingService.timeline.exactrendering = res.SearchShowExact;
            this.settingService.defaulttimeline.includereferences = this.settingService.timeline.includereferences = res.SearchShowRef;
            this.settingService.defaulttimeline.timelineshow = this.settingService.timeline.timelineshow = res.SearchTimelineShow;
            this.settingService.defaulttimeline.sumoptions = this.settingService.timeline.sumoptions = (res.ChartObjectDefault) ? 1 : 0;
            this.settingService.defaulttimeline.markedhide = this.settingService.timeline.markedhide = (res.HideMarked);
            this.settingService.defaulttimeline.showprofile = this.settingService.timeline.showprofile = res.SearchShowProfile;
            this.settingService.defaulttimeline.showemptyrows = this.settingService.timeline.showemptyrows = res.SearchShowEmptyRows;


            //Check watchdog
            this.settingService.checkWatchdog();
          }

          return of(this._permissions);
        })
      );
    }
    else {
      return of(this._permissions);
    }
  }
  private loadUser() {
    if (!this._user) {

      this._user = {};

      return this.dataService.tokenRequest('/api/v1/general/user', 'GET').pipe(
        take(1),
        switchMap(res => {
          if (res) {

            this._user = res;
          }

          return of(this._user);
        })
      );
    }
    else {
      return of(this._user);
    }
  }
}
