import { Component, Input, OnChanges, Output, EventEmitter, OnDestroy, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { LanguageService } from '../../_services/language.service';
import { PermissionService } from '../../_services/permission.service';
import { GeneralService } from '../../_services/general.service';
import { PropertyService } from '../../_services/property.service';
import { DataService } from '../../_services/data.service';
import { AlertService } from '../../_services/alert.service';
import { SettingService } from '../../_services/setting.service';
import { DateTimeService } from '../../_services/datetime.service';
import { ViewCacheService } from '../../_services/viewcache.service';
import { ListService } from '../../_services/list.service';
import { relatedObject } from '../../layout/profile.component';



@Component({
  selector: 'swe-bookingdata',
  templateUrl: './bookingdata.component.html'
})
export class BookingDataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id: number;
  @Input() open: boolean;
  @Input() userid: number = 0;
  @Input() levelid: number = 0;
  @Input() employmentplan: number = -1;
  @Output() bookingdataChange = new EventEmitter<any>();
  @ViewChild('sweSaveElement') saveElement: ElementRef;

  private _subscriptions: Subscription[] = [];
  private _booking: any;
  private _loading: boolean;
  private _saving: boolean;
  private _levelreset: boolean;
  private _user: string = "";
  private _checkuser: boolean = true;
  private _checknotificationemail: boolean = this.permissionService.permissions.NotifyOnBookEmail == 1 ? true : false;
  private _checknotificationsms: boolean = this.permissionService.permissions.NotifyOnBookSms == 1 ? true : false;
  private _slots: number = 1;
  private _showStatusModal: boolean;
  private _isReload: boolean;
  private _employmentcategories: any[] = [];
  private _plustimetypes: any[] = [];
  private _neutraltimetypes: any[] = [];
  private _minustimetypes: any[] = [];
  //Series
  private _series: any[] = [];
  private _isSerie: boolean;
  private _serieOptionList: any[] = [];
  private _serieOption: number = this.permissionService.permissions.SerieCopyAsDefault ? 2 : 1;
  private _endOption: number = 1;
  private _includeUsers: boolean;
  //Activities
  private _activities: any[] = null;
  private _tmpcounter: number = -1;
  //Split Shift
  private _splittimeFirst: Date;
  private _splittimeSecond: Date;
  //Message
  private _owner: any = null;
  private _hasCell: boolean = false;
  private _hasEmail: boolean = false;
  //Validation
  private _validation: any;
  private _coreChanged: any;
  //Popup
  private _checkremind: boolean = false;
  private _remind: any;
  private _popup: any[] = [{ Visible: false, Event: null, Option: 1 }, { Visible: false, Event: null, Option: 3 }];
  //Status reset
  private _originalStatus: number;
  //RelatedObjects (to profilelist)
  private _relatedObjectList: relatedObject[] = [];
  

  constructor(
    public languageService: LanguageService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    public settingService: SettingService,
    private propertyService: PropertyService,
    private dataService: DataService,
    private alertService: AlertService,
    private dateTimeService: DateTimeService,
    private viewCacheService: ViewCacheService,
    private listService: ListService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {

    this._subscriptions.push(settingService.onViewRefresh$
      .subscribe((refresh) => {

        if (refresh.type == 'bookingusers_save') {
          this.load();
        }
        else if (refresh.type == 'bookingusers_delete') {
          this.load();
        }
        else if (refresh.type == 'availableusers_save') {
          this.load();
        }

      }));

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.saveWithSerieConfirm();
      })); 
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges() {
    if (this.open) {
      if (this.levelid) {
        this.load(this.levelid);
      }
      else {
        this.load();
      }
    }
  }

  /*Properties*/
  public get loading() {
    return this._loading;
  }
  public get saving() {
    return this._saving;
  }
  public get showStatusModal() {
    return this._showStatusModal;
  }
  public get editmode() {
    return this.id > 0 ? this.settingService.editMode : true;
  }
  public get booking() {
    return this._booking;
  }
  public get levelreset() {
    return this._levelreset;
  }
  public get user() {
    return this._user;
  }
  public get checkuser() {
    return this._checkuser;
  }
  public set checkuser(val) {
    this._checkuser = val;
  }
  public get checknotificationemail() {
    return this._checknotificationemail;
  }
  public set checknotificationemail(val) {
    this._checknotificationemail = val;
  }
  public get checknotificationsms() {
    return this._checknotificationsms;
  }
  public set checknotificationsms(val) {
    this._checknotificationsms = val;
  }
  public get header() {
    if (typeof this._booking != 'undefined') {
      return this.propertyService.getProperty(this._booking.HeaderProperty);
    }
    return {};
  }
  public get max() {
    if (typeof this._booking != 'undefined') {
      return this.propertyService.getProperty(this._booking.MaxProperty);
    }
    return {};
  }
  public get slots() {
    return this._slots;
  }
  public set slots(val) {
    this._slots = val;
  }
  public get isReload() {
    return this._isReload;
  }
  public set isReload(val) {
    this._isReload = val;
  }
  public get employmentcategories() {
    return this._employmentcategories;
  }
  public get timetypes() {
    return this.getTimeTypes([0, 1]);
  }
  //Series
  public get series() {
    return this._series;
  }
  public get isSerie() {
    return this._isSerie;
  }
  public set isSerie(val) {
    this._isSerie = val;
  }
  public get serieOption() {
    return this._serieOption;
  }
  public set serieOption(val) {
    this._serieOption = val;
  }
  public get endOption() {
    return this._endOption;
  }
  public set endOption(val) {
    this._endOption = val;
  }
  public get includeUsers() {
    return this._includeUsers;
  }
  public set includeUsers(val) {
    this._includeUsers = val;
  }
  public get serieOptionList() {
    return this._serieOptionList;
  }
  public get serieTypeList() {
    return [
      { Id: 0, Name: this.languageService.getItem(813) },
      { Id: 1, Name: this.languageService.getItem(256) },
      { Id: 2, Name: this.languageService.getItem(257) },
      { Id: 3, Name: this.languageService.getItem(258) },
      { Id: 4, Name: this.languageService.getItem(259) },
      { Id: 5, Name: this.languageService.getItem(260) },
      { Id: 6, Name: this.languageService.getItem(261) },
      { Id: 7, Name: this.languageService.getItem(262) },
      { Id: 8, Name: this.languageService.getItem(263) },
      { Id: 9, Name: this.languageService.getItem(264) },
      { Id: 10, Name: this.languageService.getItem(265) }
    ];
  }
  public get serieDayList() {
    return [
      { Id: 2, Name: this.languageService.getItem(209) },
      { Id: 4, Name: this.languageService.getItem(210) },
      { Id: 8, Name: this.languageService.getItem(211) },
      { Id: 16, Name: this.languageService.getItem(212) },
      { Id: 32, Name: this.languageService.getItem(213) },
      { Id: 64, Name: this.languageService.getItem(214) },
      { Id: 1, Name: this.languageService.getItem(215) }
    ];
  }
  //Activities
  public get activities() {
    return this._activities;
  }
  //Split Shift
  public get splittimeFirst() {
    return this._splittimeFirst;
  }
  public set splittimeFirst(val) {
    this._splittimeFirst = val;
  }
  public get splittimeSecond() {
    return this._splittimeSecond;
  }
  public set splittimeSecond(val) {
    this._splittimeSecond = val;
  }
  //Container
  public get container() {
    return {
      isbit: true
    };
  }
  //Message
  public get hasCell() {
    return this._hasCell;
  }
  public get hasEmail() {
    return this._hasEmail;
  }
  public get coreChanged() {
    return this._coreChanged;
  }
  //Validation
  public get validation() {
    return this._validation;
  }
  //Popup
  public get remind() {
    return this._remind;
  }
  public set remind(val) {
    this._remind = val;
  }
  public get checkremind() {
    return this._checkremind;
  }
  public set checkremind(val) {
    this._checkremind = val;
  }
  public get popup() {
    return this._popup;
  }
  public set popup(val) {
    this._popup = val;
  }
  //RelatedObjects
  public get relatedObjectList() {
    return this._relatedObjectList;
  }




  //Methods
  public relatedObjectChange() {
    this._relatedObjectList = [];
    this._relatedObjectList.push({ Name: 'TimeType', Value: this.booking.TimeType });
  }
  public linkcontainer() {

    let res: any = {
      Link: this._booking.TemplateName,
      Url: '/admin/templates/' + this._booking.TemplateId
    }

    return res;
  }
  public deleteConfirm() {
    let confirm = this.languageService.getItem(191);
    if (this._booking && this._booking.Filled > 0) {
      confirm += ' ';
      confirm += this.languageService.getItem(1114).replace('{0}', this._booking.Filled);
    }

    return confirm;
  }
  public changeSerieType(e) {
    this._isSerie = (this._booking.Serie && this._booking.Serie.Type > 0);
  }
  seriesStatusSave(option: number = 1,) {
    let url = `/api/v1/bookings/${this._booking.Id}/series/${option}/status`;

    this._saving = true;

    this.dataService.tokenRequest(url, 'PUT', { Id: this._booking.Status }, 'text', 'response')
      .subscribe(res => {
        if (res) {
          this._saving = false;

          this.alertService.Add({ message: res.body, type: 'success' });
          this.load();
          this._originalStatus = this._booking.Status;
        }
      }, () => {
        this._saving = false;
        this._booking.Status = this._originalStatus;
      });
    this._showStatusModal = false;

  }

  seriesStatusClose() {
    this._booking.Status = this._coreChanged["Status"].Original;
    this._coreChanged["Status"].Changed = false;
    this._showStatusModal = false;
  }

  public manageCoreChange(idx, val) {
    let item = this._coreChanged[idx];
    if (item != null) {
      item.Changed = (item.Original != null && item.Original != val);
    }

    if (idx == 'Status' && this.permissionService.permissions.ChangeStatusWhenStaffed && this.id > 0) {
      if (this.isSerie && this.booking.Serie.Id > 0) {
        this._showStatusModal = true;
        return;
      }

      let url = '/api/v1/bookings/status/' + this.id;

      this._saving = true;

      this.dataService.tokenRequest(url, 'POST', { Id: this._booking.Status }, 'text', 'response')
        .subscribe(response => {
          if (response) {
            this._saving = false;

            this.alertService.Add({ message: response.body, type: 'success' });
              this.load();
            this._originalStatus = this._booking.Status;
          }
        }, (error) => {
          this._saving = false;
          this._booking.Status = this._originalStatus;
        });
    }
  }
  public saveWithSerieConfirm() {
    if (!this._saving && this._booking.SaveAccess > 1) {
      let element = this.saveElement.nativeElement;
      element.click();
    }
  }
  public profiledone() {
    this.saveWithSerieConfirm();
  }
  public levelchosen(e) {
    if (this.permissionService.permissions.ReloadProfile && e > 0) {
      this._loading = true;

      let url = '/api/v1/bookings/' + this.id;
      if (e > 0) {
        url += '/level/' + e;
      }

      this.dataService.tokenRequest(url, 'GET')
        .subscribe(res => {
          if (res) {
            this._booking.Access = res.Access;
            this._booking.Filled = res.Filled;
            this._booking.Max = res.Max;
            this._booking.Profile = res.Profile;

            if (e == 0) {
              //Storage
              let storage = localStorage.getItem('level');
              if (!storage) {
                storage = this.permissionService.permissions.BookingLevel;
              }
              if (storage) {
                let storageArr = storage.split('|');
                this._booking.LevelId = storageArr[0];
                if (storageArr.length > 1) {
                  this._booking.LevelName = storageArr[1];
                }
                if (storageArr.length > 2) {
                  this._booking.LevelPath = storageArr[2];
                }
              }
            }
            else if (e > 0 && e != res.LevelId) {
              //Changed Level on existing Shift
              this.dataService.tokenRequest('/api/v1/levels/' + e, 'GET')
                .subscribe(resLevel => {

                  this._booking.LevelId = resLevel.Id;
                  this._booking.LevelName = resLevel.Name;
                  this._booking.LevelPath = resLevel.Path;
                });
            }
            else {
              //Changed Level on new Shift
              this._booking.LevelId = res.LevelId;
              this._booking.LevelName = res.LevelName;
              this._booking.LevelPath = res.LevelPath;
            }
          }

          this._loading = false;
        });
    }
  }
  public load(levelid:number = 0) {

    this.resetValidation();

    this._isReload = true;
    setTimeout(() => {
      this._isReload = false;
    }, 500);

    this._loading = true;

    let url = '/api/v1/bookings/' + this.id;
    if (levelid > 0) {
      url += '/level/' + levelid;
    }

    this._relatedObjectList = [];

    this.dataService.tokenRequest(url, 'GET')
      .subscribe(res => {
        if (res) {

          if (this.userid > 0 && res.InstantBookAccess > 0) {
            this.dataService.tokenRequest('/api/v1/users/' + this.userid + '/name', 'GET')
              .subscribe(resUser => {

                this._user = `${resUser.Firstname} ${resUser.Lastname} (${resUser.Username})`;
              },
                (error) => {
                  this.userid = 0;
                }); 
          }
          else {
            this.userid = 0;
            this._user = '';
          }

          if (res.UserId > 0) {
            this.preload(res.UserId);
          }
          
          /*Booking*/
          if (res.Id > 0) {

            //230515 - Moved to server

            //let hourdiff = (new Date(res.End).getTime() - new Date(res.Start).getTime()) / (1000 * 60 * 60);
            //hourdiff -= (res.Break / 60);

            res.ShiftLength = '(' + this.generalService.formatdecimal(res.TotalLength) + 'h)';

          }
          else {
            if (this.levelid > 0) {
              //Get
              this.dataService.tokenRequest('/api/v1/levels/' + this.levelid, 'GET')
                .subscribe(resLevel => {

                  res.LevelId = resLevel.Id;
                  res.LevelName = resLevel.Name;
                  res.LevelPath = resLevel.Path;
                },
                  (error) => {
                    this.levelid = 0;
                  });
            }
            else {
              //Storage
              let storage = localStorage.getItem('level');
              if (!storage) {
                storage = this.permissionService.permissions.BookingLevel;
              }
              if (storage) {
                let storageArr = storage.split('|');
                res.LevelId = storageArr[0];
                if (storageArr.length > 1) {
                  res.LevelName = storageArr[1];
                }
                if (storageArr.length > 2) {
                  res.LevelPath = storageArr[2];
                }
              }
            }

            let start: any = this.viewCacheService.get('booking_start');
            if (start) {
              let defaultstart = new Date(res.Start);
              let viewstart = start.value;
              if (viewstart.getHours() > 0) {
                res.Start = this.dateTimeService.formatWithTimeZone(new Date(viewstart.getFullYear(), viewstart.getMonth(), viewstart.getDate(), viewstart.getHours(), viewstart.getMinutes()));
              }
              else {
                res.Start = this.dateTimeService.formatWithTimeZone(new Date(viewstart.getFullYear(), viewstart.getMonth(), viewstart.getDate(), defaultstart.getHours(), defaultstart.getMinutes()));
              }
            }
            let end: any = this.viewCacheService.get('booking_end');
            if (end) {
              let defaultend = new Date(res.End);
              let viewend = end.value;
              if (viewend.getHours() > 0) {
                res.End = this.dateTimeService.formatWithTimeZone(new Date(viewend.getFullYear(), viewend.getMonth(), viewend.getDate(), viewend.getHours(), viewend.getMinutes()));
              }
              else {
                res.End = this.dateTimeService.formatWithTimeZone(new Date(viewend.getFullYear(), viewend.getMonth(), viewend.getDate() - 1, defaultend.getHours(), defaultend.getMinutes()));
              }
            }
          }
          /********/

          let status = this.listService.find(this.generalService.bookingstatus, 'Id', res.Status);
          if (status) {
            res.StatusName = status.Name;
          }

          res.DateTimeHeader = this.dateTimeService.header(new Date(res.Start), new Date(res.End), false, true);

          /*Timetype*/
          if (res.TimeType == 0 && this._neutraltimetypes.length > 0) {
            res.TimeType = this._neutraltimetypes[0].Id;
          }

          /*Serie*/
          if (res.Serie.Id > 0) {
            this._isSerie = true;
            this._endOption = new Date(res.Serie.End) > new Date(1970, 1, 1) ? 1 : 0;
            this._serieOption = 1;
          }
          else {
            this._isSerie = false;
            this._endOption = 1;
            res.Serie = {
              Id: 0,
              'Type': 0,
              Days: Math.pow(2, new Date(res.Start).getDay()),
              Times: 1,
              End: this.dateTimeService.lastDayInWeek(new Date(res.Start))
            };
          }
          /********/

          //Split Shift
          this._splittimeFirst = new Date(res.Start);
          this._splittimeSecond = new Date(res.End);
          this._booking = res;
          this._loading = false;

          this._coreChanged = {
            LevelId: { Original: this._booking.Level, Changed: false },
            Status: { Original: this._booking.Status, Changed: false },
            Break: { Original: this._booking.Break, Changed: false }
          };


          if (this._booking.Id == 0) {
            this.levelchosen(this._booking.LevelId);
            if (this.employmentplan == 1 && this.permissionService.permissions.EmploymentPlan > 0) {
              res.EmploymentPlan = true;
            }
            if (this.employmentplan == 0) {
              res.EmploymentPlan = false;
            }
          }

          if (this._booking.Id == 0) {
            this.manageBreak();
          }

          this.bookingdataChange.emit({
            Id: this._booking.Id,
            RemindAccess: this._booking.RemindAccess,
            RemindSmsDefault: this._booking.RemindSmsDefault,
            Status: this._booking.Status,
            Slots: this._booking.Slots,
            Filled: this._booking.Filled,
            Max: this._booking.Max,
            SerieId: this._booking.Serie ? this._booking.Serie.Id : 0,
            Created: this._booking.Created,
            Start: this._booking.Start,
            End: this._booking.End,
            UsersAccess: this._booking.UsersAccess,
            RequestAccess: this._booking.RequestAccess,
            LogAccess: this._booking.LogAccess,
            UserId: this._booking.UserId,
            AffectAllOnly: this._booking.AffectAllOnly,
            RemoveATK: this._booking.RemoveATK,
            LevelId: this._booking.LevelId,
            IsActiveNow: this._booking.IsActiveNow,
            IsActiveText: this._booking.IsActiveText
          });
          this._originalStatus = this._booking.Status;
          let start = new Date(this.booking.Start);
          this._remind = this.dateTimeService.formatWithTimeZone(new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes() - this.permissionService.permissions.RemindDelay));

          this.relatedObjectChange();
        }
      });
  }
  public save(option: number = 1, exclude: boolean = false) {

    if (!this.validatedatetime() || !this.validatebreak()) { return; }
    if (!this.validatetimetypes()) { return; }
    if (!this.validatecorevalues()) { return; }
    if (!this.propertyService.validate(this._booking.Profile)) { return; }

    this._saving = true;

    let verb = 'POST';
    let path = '/api/v1/bookings/'
    if (this.id > 0) {
      verb = 'PUT';
      path += this.id;

      if (option > 1) {
        path += '/series/' + option;
      }
    }

    //Manage Break
    for (let i = 0; i < this._booking.Breaks.length; i++) {
      if (new Date(this._booking.Breaks[i].BreakStart) <= new Date(1970, 1, 1) || this._booking.Breaks[i].Break <= 0) {
        if (this.permissionService.permissions.BreakStart || this._booking.Breaks[i].Break <= 0) {
          this._booking.Breaks.splice(i, 1);
          i--;
        }
        else {
          this._booking.Breaks[i].BreakStart = '0001-01-01T00:00:00Z';
        }
      }
    }
    

    if (this._booking.MealBreak && this.permissionService.permissions.MealBreakAccess > 1) {
      this._booking.Breaks = [];
    }

    let dto: any = {
      LastModified: this._booking.LastModified,
      Start: this._booking.Start,
      End: this._booking.End,
      MealBreak: this._booking.MealBreak,
      Breaks: this._booking.Breaks,
      Header: this._booking.Header,
      Max: this._booking.Max,
      Profile: this._booking.Profile,
      Serie: this._booking.Serie,
      RemoveATK: this._booking.RemoveATK,
      EmploymentCategoryId: this._booking.EmploymentCategoryId ? this._booking.EmploymentCategoryId : 0,
      TimeType: this._booking.TimeType && this._booking.TimeType != 'undefined' ? this._booking.TimeType : 0
    };

    //Exclude when copy
    if (!exclude) {
      if (this.permissionService.permissions.HideLevels) {
        dto["LevelId"] = -1;
      }
      else {
        dto["LevelId"] = this._booking.LevelId;
      }
      dto["UserId"] = this._booking.UserId;
      if (this.permissionService.permissions.HideStatus) {
        dto["Status"] = 20;
      }
      else {
        dto["Status"] = this._booking.Status;
      }
      dto["Auto"] = this._booking.Auto;
      dto["EmploymentPlan"] = this._booking.EmploymentPlan;
    }

    //Hidden User
    if (this.userid > 0 && this._checkuser) {
      dto["BookId"] = this.userid;
      dto["ManualNotifyEmail"] = this._checknotificationemail;
      dto["ManualNotifySms"] = this._checknotificationsms;
    }

    //Series
    if (this._isSerie && this._booking.Serie.Id == 0) {
      dto["Serie"]["IncludeUsers"] = this._includeUsers;
      dto["Serie"]["Action"] = this._serieOption;
      if (this._endOption == 0) {
        dto["Serie"]["End"] = new Date('0001-01-01T00:00:00Z');
      }
      else {
        dto["Serie"]["Times"] = 0;
      }
    }

    if (this._booking.Id == 0 && this._activities != null) {
      dto["Activities"] = this._activities.filter(function (activity) { return activity.Id < 0; });
      if (dto["Activities"] != null) {
        let bStart = this._booking.Start;
        let bEnd = this._booking.End;
        for (let i = 0; i < dto["Activities"].length; i++) {
          let aStart = dto["Activities"][i].Start;
          let aEnd = dto["Activities"][i].End;
          let startOutsideSpan = (aStart < bStart || aStart > bEnd);
          let endOutsideSpan = (aEnd < bStart || aEnd > bEnd);
          if (startOutsideSpan || endOutsideSpan) {
            this.alertService.Add({ message: this.languageService.getItem(740), type: 'danger' });
            this._saving = false;
            return;
          }
        }
      }
    }

    this.dataService.tokenRequest(path, verb, dto, 'text', 'response')
      .subscribe(response => {
        if (response) {
          this._saving = false;

          let type = 'success';
          if (response.body != this.languageService.getItem(18)) {
            type = 'warning';
          }

          this.alertService.Add({ message: response.body, type: type });

          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          }
          else {
            this.load();
            this.loadSeries();
          }
        }
      }, (error) => {
        this._saving = false;

        if (this._booking.Breaks.length == 0) {
          this._booking.Breaks.push({
            Break: 0,
            BreakStart: null,
            ForeignId: this.id,
            Id: 0,
            LastModified: '0001-01-01T00:00:00'
          });
        }
      });
  }
  public copy() {

    if (!this.validatedatetime() || !this.validatebreak(true)) { return; }
    if (!this.validatetimetypes()) { return; }
    if (!this.validatecorevalues()) { return; }
    if (!this.propertyService.validate(this._booking.Profile)) { return; }

    //Manage Break
    for (let i = 0; i < this._booking.Breaks.length; i++) {
      if (new Date(this._booking.Breaks[i].BreakStart) <= new Date(1970, 1, 1) || this._booking.Breaks[i].Break <= 0) {
        if (this.permissionService.permissions.BreakStart || this._booking.Breaks[i].Break <= 0) {
          this._booking.Breaks.splice(i, 1);
          i--;
        }
        else {
          this._booking.Breaks[i].BreakStart = '0001-01-01T00:00:00Z';
        }
      }
    }
   
    let dto: any = {
      LastModified: this._booking.LastModified,
      CopyFrom: this.id,
      Start: this._booking.Start,
      End: this._booking.End,
      Breaks: this._booking.Breaks,
      MealBreak: this._booking.MealBreak,
      Header: this._booking.Header,
      Max: this._booking.Max,
      LevelId: this._booking.LevelId,
      UserId: this._booking.UserId,
      Auto: this._booking.Auto,
      EmploymentPlan: this._booking.EmploymentPlan,
      Status: this._booking.Status,
      Profile: this._booking.Profile,
      RemoveATK: this._booking.RemoveATK,
      EmploymentCategoryId: this._booking.EmploymentCategoryId ? this._booking.EmploymentCategoryId : 0,
      TimeType: this._booking.TimeType && this._booking.TimeType != 'undefined' ? this._booking.TimeType : 0
    };

    this.dataService.tokenRequest('/api/v1/bookings/', 'POST', dto, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });
          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          }
          else {
            this.load();
          }
        }
      });
  }
  public delete(option: number = 1) {

    let series = '';
    if (option > 1) {
      series = '/series/' + option;
    }

    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + series, 'DELETE', {}, 'text')
      .subscribe(res => {
        if (res) {

          this.alertService.Add({ message: res, type: 'success' });

          this.location.back();
        }
      });
  }
  //Serie
  public loadSeries() {
    if (this._booking.Serie && this._booking.Serie.Id > 0) {

      this._series = [];

      this.dataService.tokenRequest('/api/v1/bookings/series/' + this._booking.Serie.Id, 'GET')
        .subscribe(res => {
          if (res) {

            res.forEach(serie => {

              let start = new Date(serie.Start);
              let end = new Date(serie.End);

              serie.dateheader = this.dateTimeService.header(start, end, false);

              this._series.push(serie);
            });
          }
        });
    }
  }
  public deleteSerie() {

    this._booking.Serie.Id = -1;

    this.save();
  }
  public openSerie(id, e) {

    if (this._booking.Id != id) {
      e.stopPropagation();

      //Reset Activities
      this._activities = null;

      let url = '/bookings/' + id;

      if (e.ctrlKey || e.metaKey) {
        window.open(url, '_blank');
      }
      else {
        this.router.navigate([url]);
      }
    }
  }
  //Activities
  public openActivities(e) {
    if (this._activities == null) {
      this.loadActivities(e);
    }
  }
  public loadActivities(e) {
    if (this.permissionService.permissions.Activity > 0) {

      this._activities = [];

      this.dataService.tokenRequest('/api/v1/bookings/' + this._booking.Id + '/activities', 'GET')
        .subscribe(res => {
          if (res) {

            res.forEach(activity => {

              let start = new Date(activity.Start);
              let end = new Date(activity.End);

              if (!this.permissionService.permissions.ShiftTime) {
                end = new Date(end.getTime() + (1 * 24 * 60 * 60 * 1000));
              }

              activity.dateheader = this.dateTimeService.header(start, end, false);

              this._activities.push(activity);
            });

          }

          this._activities.unshift(this.newActivity());
          if (this._activities.length > 1) {
            this._activities[0].Start = this._activities[this._activities.length - 1].End;
          }
        });
    }
  }
  public editActivity(activity, e) {
    if (this.permissionService.permissions.Activity > 1) {
      e.stopPropagation();
      if (activity.Id == 0) {
        activity.Start = this.booking.Start;
        activity.End = this.booking.End;
      }

      activity.isedit = !activity.isedit;
    }
  }
  public saveActivity(activity, e) {
    if (activity.TypeId == 0) {
      //Take first
      activity.TypeId = this.generalService.activitytypes[0].Id;
    }

    let aStart = new Date(activity.Start);
    let aEnd = new Date(activity.End);
    let bStart = new Date(this._booking.DateTimeMin);
    if (new Date(this._booking.Start) < bStart) {
      bStart = new Date(this._booking.Start);
    }
    let bEnd = new Date(this._booking.DateTimeMax);
    if (new Date(this._booking.End) > bEnd) {
      bEnd = new Date(this._booking.End);
    }
    let startOutsideSpan = (aStart < bStart || aStart > bEnd);
    let endOutsideSpan = (aEnd < bStart || aEnd > bEnd);
    if (startOutsideSpan || endOutsideSpan) {
      this.alertService.Add({ message: this.languageService.getItem(740), type: 'danger' });
      return;
    }


    activity.BookingId = this._booking.Id;

    if (this._booking.Id > 0) {
      //Booking exits
      let id = activity.Id;
      let verb = 'POST';
      var path = '/api/v1/activities/'
      if (id > 0) {
        verb = 'PUT';
        path += id;
      }

      this.dataService.tokenRequest(path, verb, activity, 'text')
        .subscribe(res => {

          activity.isedit = false;

          this.loadActivities(e);

        });
    }
    else if (this._booking.Id == 0) {
      //Booking not saved yet
      activity.isedit = false;

      if (activity.Id == 0) {
        let tmpActivity = Object.assign({}, activity); //Copy
        tmpActivity.Id = this._tmpcounter;
        this._tmpcounter--;

        let start = new Date(tmpActivity.Start);
        let end = new Date(tmpActivity.End);

        if (!this.permissionService.permissions.ShiftTime) {
          end = new Date(end.getTime() + (1 * 24 * 60 * 60 * 1000));
        }

        tmpActivity.dateheader = this.dateTimeService.header(start, end, false);

        let activitytype = this.generalService.activitytypes.filter(function (a) { return a.Id == activity.TypeId; });
        if (activitytype) {
          tmpActivity.Color = activitytype[0].Color;
        }

        this._activities.push(tmpActivity);

        if (this._activities.length > 1) {
          this._activities[0].Start = this._activities[this._activities.length - 1].End;
        }
      }
      else {
        let start = new Date(activity.Start);
        let end = new Date(activity.End);

        if (!this.permissionService.permissions.ShiftTime) {
          end = new Date(end.getTime() + (1 * 24 * 60 * 60 * 1000));
        }

        activity.dateheader = this.dateTimeService.header(start, end, false);

        let activitytype = this.generalService.activitytypes.filter(function (a) { return a.Id == activity.TypeId; });
        if (activitytype) {
          activity.Color = activitytype[0].Color;
        }
      }
    }
  }
  public deleteActivity(activity, e) {

    e.stopPropagation();

    if (this._booking.Id > 0) {
      //Booking exits
      this.dataService.tokenRequest('/api/v1/activities/' + activity.Id, 'DELETE', {}, 'text')
        .subscribe(res => {

          activity.isedit = false;

          this.loadActivities(e);

        });
    }
    else {
      //Booking not saved yet
      let index = this._activities.indexOf(activity);
      if (index > -1) {
        this._activities.splice(index, 1);
      }
    }
  }
  public bookMyselfPopUp(option: number = 1, type) {
    if (this._booking.RemindAccess && type==1) {
      this._popup[0].Visible = true;
      this._popup[0].Event = type;
      this._popup[0].Option = option;
    }
    else if (this.permissionService.permissions.DetailWhenAbscent && type == 3) {
      this._popup[1].Visible = true;
      this._popup[1].Event = type;
      this._popup[1].Option = option;
    }
    else {
      this.bookMyself(option, type);
    }
  }
  public bookMyself(option: number = 1, type) {

    //if (type == 3 && this.permissionService.permissions.DetailWhenAbscent) {
      //Abscent

      //let url = '/timereports/' + this._booking.MySelfTimereport + '/user/' + this.permissionService.user.Id + '/type/' + type;

      //this.router.navigate([url]);
    //  return;
    //}

    let filter = {
      Type: type,
      Slots: this._slots,
      ManualNotify: true,
      ManualNotifyEmail: this._checknotificationemail,
      ManualNotifySms: this._checknotificationsms
    };

    if (option > 1) {
      filter["Series"] = option;
    }

    //let remindsms = this.permissionService.permissions.RemindSms;
    //if (remindsms > 1) {
    //  let start = new Date(this._booking.Start);
    //  let remind = this.dateTimeService.formatWithTimeZone(
    //    new Date(start.getFullYear(),
    //      start.getMonth(),
    //      start.getDate(),
    //      start.getHours(),
    //      start.getMinutes() - this.permissionService.permissions.RemindDelay));
    //  filter["Remind"] = remind;
    //}
    if (this._checkremind) {
      filter["Remind"] = this._remind;
    }


    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + '/users/' + this.permissionService.user.Id, 'POST', filter, 'text')
      .subscribe((res) => {

        this.alertService.Add({ message: res, type: 'success' });

        if (this.permissionService.permissions.SaveAfterBook) {
          this.save();
        }
        else {
          this.load();
        }

      });

  }
  public unbookMyself(option: number = 1, type) {

    let notify = 0;
    if (this._checknotificationsms) {
      notify |= 1;
    }
    if (this._checknotificationemail) {
      notify |= 2;
    }

    let extra = '/notify/' + notify;
    
    if (option > 1) {
      extra += '/series/' + option;
      extra += '/type/' + type;
    }

    this.dataService.tokenRequest('/api/v1/bookings/' + this.id + '/users/' + this.permissionService.user.Id + extra, 'DELETE', {}, 'text')
      .subscribe(res => {

        this.alertService.Add({ message: res, type: 'success' });

        this.load();
      });

  }
  public reloadAfterBook() {
    if (this.permissionService.permissions.SaveAfterBook && this._booking.SaveAccess > 1) {
      this.save();
    }
    else {
      this.load();
    }
  }
  public send(msgtype, e) {
    e.stopPropagation();

    if (this._owner != null) {

      let url = '/messages/send/';

      if (msgtype == 1) {
        url += 'email/id/' + this.id;this.booking
        url += '/serie/' + this._booking.Serie.Id + '/body/42/subject/43';
      }
      else if (msgtype == 3) {
        url += 'sms/id/' + this.id;
        url += '/serie/' + this._booking.Serie.Id + '/body/42';
      }

      let userId = this._booking.UserId;

      this.viewCacheService.add('message_users', [this._owner]);
      this.viewCacheService.add('message_disableTo', true);

      this.router.navigate([url]);
    }

  }
  public manageDateTime(e) {

    this.manageBreak();

    //Split Shift
    this._splittimeFirst = new Date(this._booking.Start);
    this._splittimeSecond = new Date(this._booking.End);
  }
  public splitShift() {

    if (!this.validatesplit()) { return; }

    let dto = {
      'SplitFirst': this._splittimeFirst,
      'SplitSecond': this._splittimeSecond
    }

    this.dataService.tokenRequest('/api/v1/bookings/split/' + this._booking.Id, 'POST', dto, 'text', 'response')
      .subscribe(response => {
        if (response) {

          this.alertService.Add({ message: response.body, type: 'success' });
          if (response.status == 201) {
            this.router.navigate([response.headers.get('Location')], { replaceUrl: true });
          }
        }
      });
  }


  //Functions
  private getTimeTypes(signs: any[]) {

    let list: any[] = [];

    signs.forEach((sign) => {

      if (list.length > 0) {
        list.push({ Name: '-----' });
      }

      if (sign > 0) {
        this._plustimetypes.forEach((item) => {
          list.push(item);
        });
      }
      else if (sign == 0) {
        this._neutraltimetypes.forEach((item) => {
          list.push(item);
        });
      }
      else {
        this._minustimetypes.forEach((item) => {
          list.push(item);
        });
      }

    });

    return list;
  }
  private adjustbreakstart(start: Date, end: Date, breakstart:Date) {

    let newBreakStart: Date = breakstart;

    if ((newBreakStart < start || end < newBreakStart)) {
      //Adjust to start date
      newBreakStart = new Date(start.getFullYear(), start.getMonth(), start.getDate(), breakstart.getHours(), breakstart.getMinutes(), breakstart.getSeconds());
      if ((newBreakStart < start || end < newBreakStart)) {
        //Adjust to end date
        newBreakStart = new Date(end.getFullYear(), end.getMonth(), end.getDate(), breakstart.getHours(), breakstart.getMinutes(), breakstart.getSeconds());
        if ((newBreakStart < start || end < newBreakStart)) {
          //Can't adjust
          newBreakStart = breakstart;
        }
      }
    }

    return this.dateTimeService.formatWithTimeZone(newBreakStart);
  }
  private preload(userId) {
    
    this.dataService.tokenRequest('/api/v1/users/' + userId + '/small', 'GET')
      .subscribe((res) => {

        this._owner = res;
        this._hasCell = this._owner.Cell.length > 0;
        this._hasEmail = this._owner.Email.length > 0;
      });
  }
  private validatebreak(isCopy: boolean = false) {
    if (this._booking.MealBreak) {
      return true;
    }
    let breaks: any[] = [{ Break: this._booking.Break, BreakStart: new Date(this._booking.BreakStart) }];
    breaks = this._booking.Breaks;
    

    let res = true;
    for (let i = 0; i < breaks.length; i++) {
      let breakObj = breaks[i];

      if (this.permissionService.permissions.BreakStart && breakObj.Break > 0) {
        if (breakObj.BreakStart == null || breakObj.BreakStart == '0001-01-01T00:00:00+00:00') {
          this.alertService.Add({ type: 'danger', message: this.languageService.getItem(787) });

          res = false;
          break;
        }
        else if (!isCopy && (breakObj.BreakStart < new Date(this._booking.Start) || new Date(this._booking.End) < breakObj.BreakStart)) {
          let debugstart = this.dateTimeService.format(new Date(this._booking.Start), 'yyyy-MM-dd HH:mm'); 
          let debugbreak = this.dateTimeService.format(new Date(breakObj.BreakStart), 'yyyy-MM-dd HH:mm');
          let debugend = this.dateTimeService.format(new Date(this._booking.End), 'yyyy-MM-dd HH:mm');
          console.log(this.languageService.getItem(788) + ' (' + debugstart + ', ' + debugbreak + ', ' + debugend + ')');
          this.alertService.Add({ type: 'danger', message: this.languageService.getItem(788) });

          res = false;
          break;
        }
        else if ((new Date(breakObj.BreakStart).getTime() + (breakObj.Break * 60 * 1000)) > new Date(this._booking.End).getTime()) {
          let debugstart = this.dateTimeService.format(new Date(this._booking.Start), 'yyyy-MM-dd HH:mm');
          let debugbreak = this.dateTimeService.format(new Date(breakObj.BreakStart), 'yyyy-MM-dd HH:mm');
          let debugend = this.dateTimeService.format(new Date(this._booking.End), 'yyyy-MM-dd HH:mm');
          console.log(this.languageService.getItem(789) + ' (' + debugstart + ', ' + debugbreak + ', ' + debugend + ')');
          this.alertService.Add({ type: 'danger', message: this.languageService.getItem(789) });

          res = false;
          break;
        }
      }
      else if (this.permissionService.permissions.BreakStart && breakObj.Break == 0) {
        breakObj.BreakStart = null;
      }

    }

    return this._validation.break = res;
  }
  private validatedatetime() {

    let start = this._booking.Start ? new Date(this._booking.Start) : this._booking.Start;
    let end = this._booking.End ? new Date(this._booking.End) : this._booking.End;

    /*Manage ShiftTime*/
    if (!this.permissionService.permissions.ShiftTime) {

      start = new Date(start.getFullYear(), start.getMonth(), start.getDate());
      end = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    }

    /*Test*/
    return this._validation.datetime = this.dateTimeService.validation(start, end);
  }
  private validatetimetypes() {

    //TimeType
    if (this.timetypes.length > 0) {
      if (this._booking.TimeType == 0) {
        this.alertService.Add({ message: this.languageService.getItem(1124), type: 'danger' });
        return false;
      }
      else if (this._booking.TimeType > 0) {
        let timeTypeObj = this.listService.find(this.timetypes, 'Id', this._booking.TimeType);
        if (!timeTypeObj) {
          this.alertService.Add({ message: this.languageService.getItem(1124), type: 'danger' });
          return false;
        }
      }
    }

    return true;
  }
  private validatecorevalues() {

    //Header
    let header = this.propertyService.getPropertyByCoreValue('BookingHeader');
    if (header) {
      this._validation.header = this.propertyService.validate([{ Name: header.Name, Value: this._booking.Header, Property: header.Id, Access: 2 }]);
      if (!this._validation.header) {
        return false;
      }
    }

    return true;
  }
  private validatesplit() {

    let start = new Date(this._booking.Start);
    let end = new Date(this._booking.End);
    let splitFirst = new Date(this._splittimeFirst);
    let splitSecond = new Date(this._splittimeSecond);

    /*Test*/
    if (!(start < splitFirst && splitFirst < end || start < splitSecond && splitSecond < end)) {
      //At least one of the split bounds must be in between start and end
      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1031) });
      return false;
    }
    else if (splitFirst >= splitSecond) {
      //First split bounds must be before the second
      this.alertService.Add({ type: 'danger', message: this.languageService.getItem(1128) });
      return false;
    }
    
    return true;
  }
  private init() {
    this._serieOptionList.push({ Id: 1, Name: 252 });
    this._serieOptionList.push({ Id: 2, Name: 253 });

    this._checkremind = (this.permissionService.permissions.RemindSms > 1);

    this.generalService.timetypes.forEach((timetype) => {

      if (timetype.Extra < 0) {
        this._minustimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }
      else if (timetype.Extra == 0) {
        this._neutraltimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }
      else {
        this._plustimetypes.push({ Id: timetype.Key, Name: timetype.Value });
      }

    });

    this.generalService.employmentcategories.forEach((employmentcategory) => {

      this._employmentcategories.push({ Id: employmentcategory.Key, Name: employmentcategory.Value });

    });
   }

  private manageBreak() {
    if (this.permissionService.permissions.BreakStart) {
        for (let i = 0; i < this._booking.Breaks.length; i++) {
          if (new Date(this._booking.Breaks[i].BreakStart) > new Date(1970, 1, 1)) {
            this._booking.Breaks[i].BreakStart = this.adjustbreakstart(new Date(this._booking.Start), new Date(this._booking.End), new Date(this._booking.Breaks[i].BreakStart));
          }
        }
    }
  }


  private resetValidation() {
    this._validation = {
      datetime: true,
      break: true,
      header: true
    };
  }

  private newActivity() {
    return {
      Id: 0,
      Start: this._booking.DateTimeMin,
      End: this._booking.DateTimeMax,
      TypeId: 0,
      Text: '',
      BookingId: 0,
      isedit: false
    };
  }
}
